<template>
	<div class="technician_verify">
		<el-card>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane v-for="(pane,p_idx) in pane_list" :label="pane" :name="pane">
					<el-table border :data="verify_list" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
						<el-table-column prop="verify_id" label="ID"></el-table-column>
						<el-table-column prop="jishi_name" label="技师名称"></el-table-column>
						<el-table-column v-if="verify_mode==3" :show-overflow-tooltip="true" prop="content" label="解约理由" width="200"></el-table-column>
						<el-table-column v-else label="申请图片">
							<template slot-scope="scope">
								<div class="cell el-tooltip">
									<span v-for="(item,index) in scope.row.content" :key="index" class="el-avatar el-avatar--square" style="height: 25px; width: 25px; line-height: 25px;margin-left: 5px;">
										<el-image :src="item" fit="fit" :preview-src-list="scope.row.content"></el-image>
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column v-if="verify_mode==3" :show-overflow-tooltip="true" prop="content_int" label="解约时间" width="160"></el-table-column>
						<el-table-column prop="add_time" label="申请时间" width="160"></el-table-column>
						<el-table-column label="审核状态">
							<template slot-scope="scope">
								<el-tag type="warning" v-if="scope.row.is_verify" disable-transitions>{{scope.row.verify_status_name}}</el-tag>
								<el-tag type="primary" v-else disable-transitions>{{scope.row.verify_status_name}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column :show-overflow-tooltip="true" prop="verify_remark" label="审核备注" width="200"></el-table-column>
						<el-table-column prop="verify_time" label="审核时间" width="160"></el-table-column>
						<el-table-column fixed="right" label="操作" width="100" >
							<template slot-scope="scope" v-if="!scope.row.is_verify">
								<div>
									<el-button type="primary" plain size="small" @click="setVerify(scope.row)">审核</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
				</el-tab-pane>
				<!--技师签证-->
				<el-tab-pane label="技师签证" name="技师签证">
					<el-table border :data="visa_list" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
						<el-table-column prop="visa_id" label="ID"></el-table-column>
						<el-table-column prop="jishi_name" label="技师名称"></el-table-column>
						<el-table-column prop="from_city" label="来源城市" width="100"></el-table-column>
						<el-table-column prop="to_city" label="去往城市" width="100"></el-table-column>
						<el-table-column prop="start_time" label="开始时间" width="140"></el-table-column>
						<el-table-column prop="end_time" label="结束时间" width="140"></el-table-column>
						<el-table-column :show-overflow-tooltip="true" prop="content" label="申请理由" width="180"></el-table-column>
						<el-table-column label="签证状态">
							<template slot-scope="scope">
								<el-tag type="warning" v-if="scope.row.is_yx" disable-transitions>{{scope.row.yx_status_name}}</el-tag>
								<el-tag type="primary" v-else disable-transitions>{{scope.row.yx_status_name}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="审核状态">
							<template slot-scope="scope">
								<el-tag type="warning" v-if="scope.row.is_verify" disable-transitions>{{scope.row.verify_status_name}}</el-tag>
								<el-tag type="primary" v-else disable-transitions>{{scope.row.verify_status_name}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="verify_time" label="审核时间" width="160"></el-table-column>
						<el-table-column prop="add_time" label="申请时间" width="160"></el-table-column>
						<el-table-column fixed="right" label="操作" width="100" >
							<template slot-scope="scope" v-if="!scope.row.is_verify">
								<div>
									<el-button type="primary" plain size="small" @click="setVerifyVisa(scope.row)">审核</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
				</el-tab-pane>
			</el-tabs>
			<!-- 审核对话框 -->
			<el-dialog title="信息审核" width="40%" :visible.sync="verifyDialog" :modal-append-to-body="false">
				<el-form ref="verifyForm" :model="verifyForm" label-width="85px" :rules="verifyRules">
					<el-row>
						<el-col :span="24">
							<el-form-item label="ID" prop="verify_id" style="text-align: left;">
								<el-input v-model.number="verifyForm.verify_id" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="审核结果" prop="is_verify">
								<el-radio v-model="verifyForm.is_verify" label="1">通过</el-radio>
								<el-radio v-model="verifyForm.is_verify" label="2">不通过</el-radio>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="备注" prop="content" style="text-align: left;">
								<el-input type="textarea" :rows="4" v-model="verifyForm.content"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="verifyDialog=false">取消</el-button>
							<el-button type="primary" @click="doSubmit">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			// 验证可出行时间
			var checktravlTime = (rule, value, cb) => {
				if(this.travlData.travl_start_time != null) {
					return cb();
				} else {
					cb(new Error("请选择开始时间"));
				}
				if(this.travlData.travl_end_time != null) {
					return cb();
				} else {
					cb(new Error("请选择结束时间"));
				}
			};
			return {
				title: "新增城市",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				activeName: '技师形象照',
				pane_list: ["技师形象照", "技师生活照", "技师解约"],
				verifyForm: {
					verify_id: '',
					is_verify: '1',
					content: ''
				},
				verifyRules: {},
				verify_mode: 1,
				verify_list: [],
				verifyDialog: false,
				visa_list: []
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getVerifyList();
			}
		},
		methods: {
			getVerifyList() {
				var url = 'jishi/verify_list';
				let params = {
					verify_mode: this.verify_mode,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.verify_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getVisaList(){
				var url = 'jishi/visa_list';
				let params = {
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.visa_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getVerifyList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getVerifyList();
			},
			handleClick(tab, event) {
				if(tab.index==3){
					this.visa_list = [];
					this.getVisaList();
				}else{
					this.verify_list = [];
					this.verify_mode = parseInt(tab.index)+1;
					this.getVerifyList();
				}
			},
			setVerify(item) {
				this.verifyForm.verify_id = item.verify_id;
				this.verifyDialog = true;
			},
			doSubmit() {
				this.$refs.verifyForm.validate(valid => {
					if(!valid) return;
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'jishi/verifySubmit';
						let params = that.verifyForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.verifyDialog = false;
								that.$refs.verifyForm.resetFields();
								that.$message.success('操作成功');
								if(that.verifyForm.is_visa){
									that.getVisaList();
								}else{
									that.getVerifyList();
								}
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.verifyDialog = false;
							that.$message.error('网络错误');
						});
					}, 1000);
				})
			},
			setVerifyVisa(item){
				this.verifyForm.verify_id = item.visa_id;
				this.verifyForm.is_visa = 1;
				this.verifyDialog = true;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.technician_verify {
		.left {
		    text-align: left;
		}
		
		.ml30 {
		    margin-left: 30px;
		}
		
		.tooltips {
		    font-size: 18px;
		    margin-left: 15px
		}
		
		.mb15 {
		    margin-bottom: 15px;
		}
		
		.center {
		    text-align: center;
		}
	}
</style>